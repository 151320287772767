html {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2rem;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
}

body {
  margin: 4rem;
  padding: 0;
  background-color: #334455;
  color: #aaadff;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: 'Graphik', 'Helvetica Neue', 'Avenir Next', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
