.grid-container {
  position: relative;
  display: grid;
  margin: 2rem;
  padding: 0;
  grid-template-columns: repeat(8, 8vw);
  grid-template-rows: repeat(8, 8vw);
  grid-column-gap: 0;
  grid-row-gap: 0;
  color: #1a5c71;
}


.grid-square-dark {
  background-color: #738383;
  border: 1px solid #1a5c71;
  position: relative;
  text-align: left;
  font-size: 0.7rem;
  z-index: 1;
}

.grid-square-dark:hover,
.grid-square-dark:focus,
.grid-square-dark:active {
  border-color: #00ffff;
  color: #00ffff;
}

.grid-square-light {
  background-color: #aaadff;
  border: 1px solid #1a5c71;
  position: relative;
  text-align: left;
  font-size: 0.7rem;
  z-index: 1;
}

.grid-square-light:hover,
.grid-square-light:focus,
.grid-square-light:active {
  border-color: #00ffff;
  color: #00ffff;
}

.chess-piece-image {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
}

