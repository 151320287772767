@font-face {
  font-family: 'Prime Light';
  src: url('/public/fonts/Prime-Light.eot');
  src: url('/public/fonts/Prime-Light.eot?#iefix') format('embedded-opentype'),
       url('/public/fonts/Prime-Light.woff2') format('woff2'),
       url('/public/fonts/Prime-Light.woff') format('woff'),
       url('/public/fonts/Prime-Light.ttf') format('truetype'),
       url('/public/fonts/Prime-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


.App {
  text-align: center;
  margin: 4rem;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-family: 'Prime Light';
}

.App h1,h2,h3,h4,h5 {
  font-weight: 100;
  
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Title {

  font-size: 2.4rem;
}

.Footer {
  display: flex;
  flex: 1;
  width: 100%;
  padding: 2rem 0;
  font-size: 1.1rem;
  border-top: 1px solid #d9b3ff;
  justify-content: center;
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
